import { LazyLoadImageModule } from 'ng-lazyload-image'
import { MentorSharedModule } from './../mentor-shared/mentor-shared.module'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { SharedModule } from 'src/app/website/shared/shared.module'
import { DataService } from './data.service'
import { RouterModule } from '@angular/router'
import { NgModule } from '@angular/core'
import { CalendarComponent } from './calendar.component'
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker'
import { ModalModule } from 'ngx-bootstrap/modal'
import { NgSelectModule } from '@ng-select/ng-select'

@NgModule({
    imports: [
        SharedModule,
        MentorSharedModule,
        ReactiveFormsModule,
        FormsModule,
        NgSelectModule,
        ModalModule.forRoot(),
        BsDatepickerModule.forRoot(),
        LazyLoadImageModule,
        RouterModule.forChild([
            {
                path: '',
                component: CalendarComponent
            }
        ])
    ],
    declarations: [CalendarComponent],
    providers: [DataService]
})
export class CalendarModule { }
