import { Component, OnInit, TemplateRef } from '@angular/core'
import { DataService } from './data.service'
import { ActivatedRoute } from '@angular/router'
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal'
import { ConstantsService } from 'src/app/services/constants.service'
import { UIHelpers } from 'src/app/helpers/ui-helpers'
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms'
import { Resp } from 'src/app/interfaces/response'
import { Events } from 'src/app/models/events'
import { IAlertService } from 'src/app/libs/ialert/ialerts.service'
import { formatDate } from '@angular/common'
import moment  from 'moment'
@Component({
    selector: 'app-monthly-view',
    templateUrl: './calendar.component.html',
    styleUrls: ['./calendar.component.css']
})
export class CalendarComponent implements OnInit {
    days = [
        'Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'
    ]
    inspections : any = []
    inspection : any
    loadingCalendar = false
    moment = moment
    scheduled = '#3B71CA'
    inProgress = '#E4A11B'
    completed = '#198754'
    canceled = '#ff0e0e'
    month: string = ''
    year: string = ''
    fIlterMonth : any = new Date()
    fIlterYear : any = new Date()
    currentdDate = moment(new Date())
    teamIds: Array<any> = []
    teamsList: Array<any> = []
    color = 'black'
    filters : any= {
        startDate: moment().clone().startOf('month').toDate(),
        endDate: moment().clone().endOf('month').toDate(),
    }
    // status: '',
        // page: 1,
        // teamIds:this.teamIds
    dataStatus = 'fetching'
    allEventsList: Array<any> = []
    eventsList: Array<any> = []
    calendar: any = [];
    breadCrum = [
        {
            link: '/mentor/calendar',
            value: 'Calendar'
        }
    ]
    waiting: {
        search: boolean
        save: boolean
        userStatus: Array<any>
    }

    eventsForm: FormGroup

    selectedIndex: number
    modalTitle = ''
    modalRef: BsModalRef
    editorConfig: any = ConstantsService.EDITOR_CONFIG
   // prevStartTime: Moment

    constructor(
        private ds: DataService,
        private ms: BsModalService,
        public route: ActivatedRoute,
        public cs: ConstantsService,
        public ui: UIHelpers,
        private fb: FormBuilder,
        private alert: IAlertService,
    ) {
        this.waiting = {
            search: false,
            save: false,
            userStatus: []
        }
        this.eventsForm = this.fb.group({
            id: new FormControl(null),
            title: new FormControl('', [Validators.required, Validators.maxLength(100)]),
            content: new FormControl('', [Validators.required]),
            startDate: new FormControl(null, [Validators.required]),
            endDate: new FormControl('', [Validators.required]),
            startTime: new FormControl(null, [Validators.required]),
            endTime: new FormControl(null, [Validators.required]),
            teamIds: new FormControl([], [Validators.required])
        })

        // this.ds.getTeamsList().subscribe((resp: any) => {
        //     if (resp.success) {
        //         this.teamsList = resp.data?.rows
        //     }
        // })

        // this.ds.getList(this.filters).subscribe((resp: any) => {
        //     if (resp.success) {
        //         this.eventsList = resp.data?.rows
        //     }
        // })
    }
    ngOnInit() {
        this.getCalendarView()
        this.search()
        
    }


    search(){
        // this.waiting.save = true
        // this.waiting.search = true
        // // Ensure this.fIlterYear and this.fIlterMonth are valid Date objects
        this.filters = {
            startDate: moment(this.fIlterYear).clone().set('month', moment(this.fIlterMonth).month()).startOf('month').toDate(),
            endDate: moment(this.fIlterYear).clone().set('month', moment(this.fIlterMonth).month()).endOf('month').toDate(),
        };
        
        this.filters.startDate = moment(this.filters.startDate).format('YYYY-MM-DD');
        this.filters.endDate = moment(this.filters.endDate).format('YYYY-MM-DD');
        // this.currentdDate = this.filters.startDate
        this.month = moment(this.fIlterMonth).format('MMMM');
        this.year = moment(this.fIlterYear).format('YYYY'); // Use this.fIlterYear
        // this.ds.getInspections(this.filters).subscribe((resp : any) => {
        //     this.waiting.save = false
        //     this.waiting.search = false
        //         if(resp.success) {
                
        //         resp.data.forEach((e : any) => {
        //             let day = e.date
        //             e.day = new Date(day).getDate()
        //         })
        //         this.inspections = resp.data
        //         this.dataStatus = 'done'
        //     }
        // })
        // console.log(this.fIlterMonth);


        

        
       
       
      
      
    //     const startDate = new Date(`${parseInt(this.fIlterYear)}/${this.fIlterMonth}/1`)
    //     const endDate = new Date(startDate);
        console.log('Month', this.fIlterMonth);
        console.log('Year', this.fIlterYear);
    // // Set the date to the last day of the month
    //     endDate.setMonth(startDate.getMonth() + 1, 0);
            this.fIlterMonth = this.month
            this.fIlterYear = this.year
        this.ds.getInspections(this.filters).subscribe((resp: any) => {
            if (resp.success === true) {
                if(resp.success) {
                
                    resp.data.forEach((e : any) => {
                        let day = e.date
                        e.day = new Date(day).getDate()
                    })
                    this.inspections = resp.data
                    this.dataStatus = 'done'
                }
            }
        })
      

    }
    cancelButton(f: any) {
        this.modalRef.hide()
        f.resetForm()
    }
    checkDay(date: string) {
        return moment(date, "YYYY-MM-DD").format('MMMM') === this.month
    }
    getCalendarView() {
        this.inspection= []
        const selectedMonth = moment(this.currentdDate); // Parse the selected month in 'YYYY-MM' format
        const startDay = selectedMonth.clone().startOf('month').startOf('week');
        const endDay = selectedMonth.clone().endOf('month').endOf('week');
        // this.month = moment().format('MMMM')
        // this.year = moment().format('YYYY')
        // this.fIlterMonth = moment(this.currentdDate).format('MMMM')
        // this.fIlterYear = moment(this.currentdDate).format('YYYY')
        let date = startDay.clone().subtract(1, 'day')
        while (date.isBefore(endDay, 'day'))
            this.calendar.push({
                days: Array(7).fill(0).map(() => {
                    let day = date.add(1, 'day').clone()
                    return { day: Number(day.format('DD')), date: day.format('YYYY-MM-DD') }
                })
        })
        
        // this.filters.teamIds = this.teamIds
        // this.ds.getInspections(this.filters).subscribe((resp: any) => {
        //     if (resp.success === true) {
        //         this.allEventsList = resp.data
        //     }
        // })
    }

    get g() {
        return this.eventsForm.controls
    }

    save(f: any) {
        this.waiting.save = true
        if (this.eventsForm.invalid) {
            this.waiting.save = false
            this.alert.error('Please fill in all the details & try again!')
            return
        }
        if (
            Date.parse(this.eventsForm.controls.endDate.value) <
            Date.parse(this.eventsForm.controls.startDate.value)
        ) {
            this.waiting.save = false
            this.alert.error('End Date cannot be smaller than Start Date!')
            return
        }

        if (
            Date.parse(this.eventsForm.controls.startTime.value) ===
            Date.parse(this.eventsForm.controls.endTime.value)
        ) {
            this.alert.error(`Start time and end time can't be same!`)
            this.waiting.save = false
            return
        }

        if (
            Date.parse(this.eventsForm.controls.startTime.value) >
            Date.parse(this.eventsForm.controls.endTime.value)
        ) {
            this.alert.error(`End time can't be before start time`)
            this.waiting.save = false
            return
        }

        if (Date.parse(this.eventsForm.controls.endTime.value) - Date.parse(this.eventsForm.controls.startTime.value) < 3600000) {
            this.alert.error(`Event time should be at least of 1 hr!`)
            this.waiting.save = false
            return
        }

        const sDate: any = moment(this.eventsForm.controls.startDate.value).format('YYYY-MM-DD')
        const eDate: any = moment(this.eventsForm.controls.endDate.value).format('YYYY-MM-DD')
        const params = {
            id: this.eventsForm.controls.id.value,
            title: this.eventsForm.controls.title.value,
            content: this.eventsForm.controls.content.value,
            startDate: sDate,//this.eventsForm.controls.startDate.value,
            endDate: eDate,
            // startTime: this.toTime(new Date(this.eventsForm.controls.startTime.value)),
            // endTime: this.toTime(new Date(this.eventsForm.controls.endTime.value)),
            teamIds: this.teamIds
        } }
        //console.log('params', params);
        // return

        //Quil bolt extra space <p><br></p> issue resoltion
        // content: this.eventsForm.controls.content.value.replaceAll("<p><br></p>","")
        // const saveUpdate =
        //     this.eventsForm.value.id !== null ? this.ds.update(params) : this.ds.add(params)
        // saveUpdate.subscribe((resp: Resp<Events>) => {
        //     this.waiting.save = false
        //     if (resp.success === true) {
        //         if (this.eventsForm.value.id !== null) {
        //             //this.allEventsList[this.selectedIndex] = resp.data as Events
        //             this.eventsForm.controls.id.setValue(null)
        //             this.alert.success('Update Successfully')
        //             this.modalRef.hide()
        //             f.resetForm()

                    // this.ds.getList(this.filters).subscribe((resp: any) => {
                    //     if (resp.success) {
                    //         this.eventsList = resp.data?.rows
                    //     }
                    // })

                    // this.ds.getAllEvents(this.filters).subscribe((resp: any) => {
                    //     if (resp.success === true) {
                    //         this.allEventsList = resp.data
                    //     }
                    // })

    //             } else {
    //                 this.modalRef.hide()
    //                 this.eventsForm.value.id = resp.data?.id
    //                 this.allEventsList.unshift(resp.data as Events)
    //                 this.alert.success('Added Successfully')
    //                 this.modalRef.hide()
    //                 f.resetForm()
    //             }



    //         } else {
    //             this.alert.error(resp?.message as string)
    //             this.waiting.save = false
    //             return
    //         }
    //     })
    // }

    // toTime(time: any): string {
    //     const formatReq = formatDate(time, 'HH:mm:ss', 'en')
    //     return formatReq
    // }
    openModal(modal: TemplateRef<any>, inspection: any) {
       
        this.inspection = inspection
        // if (this.courseStudents === null) {
        //     return
        // }
        this.modalRef = this.ms.show(modal, {
            class: 'modal-md modal-dialog-centered back-office-panel',
            backdrop: 'static',
            ignoreBackdropClick: true,
            keyboard: false
        })
    }
    // openModal(modal: TemplateRef<any>, index: number) {
    //     this.selectedIndex = index
    //     this.modalTitle = 'Edit Event'
    //     this.eventsForm.patchValue(this.allEventsList[index])
    //     this.eventsForm.controls.id.setValue(this.allEventsList[index].id)
    //     this.eventsForm.controls.startDate.setValue(new Date(this.allEventsList[index].startDate + ' 00:00'))
    //     this.eventsForm.controls.endDate.setValue(new Date(this.allEventsList[index].endDate + ' 00:00'))
    //     console.log('todate local', moment.utc(this.allEventsList[index].startDate).local().toDate())
    //     // this.eventsForm.controls.startDate.setValue(moment(this.allEventsList[index].startDate).format('YYYY-MM-DD'))
    //     // this.eventsForm.controls.endDate.setValue(moment(this.allEventsList[index].endDate).format('YYYY-MM-DD'))
    //     this.eventsForm.controls.startTime.setValue(
    //         moment(this.allEventsList[index].startTime, 'HH:mm:ss').toString()
    //     )

    //     this.eventsForm.controls.endTime.setValue(
    //         moment(this.allEventsList[index].endTime, 'HH:mm:ss').toString()
    //     )
    //     if (this.allEventsList[index].Teams.length > 0) {
    //         const ids: any = []
    //         const names: any = []
    //         this.allEventsList[index].Teams.forEach((e: any) => {
    //             ids.push(e.id)
    //             names.push(e.title)
    //         })
    //         this.eventsForm.controls.teamIds.setValue(ids)
    //         this.teamIds = ids
    //     }

    //     this.modalRef = this.ms.show(modal, {
    //         class: 'modal-lg modal-dialog-centered back-office-panel',
    //         backdrop: 'static',
    //         ignoreBackdropClick: true,
    //         keyboard: false
    //     })

    //     this.prevStartTime = moment(this.eventsForm.controls.startTime.value)
    // }

    checkEvent(p: any, date: any): any {
        // console.log(p,'stdate---',date,'data');
        if(p == date) {
            return true
        }else {
            return false
        }  

            
        
    }
    // printEvent(e: any) {
    //     return `${moment(new Date(e.startTime)).format('hh:mm a')} - ${e.participantId.firstName}`
    // }
    nextMonth() {
        // this.calendar = []
        // // console.log(moment(new Date()).add(1, 'months').endOf('month').format('YYYY-MM-DD'))
        // const currentDate = moment(this.currentdDate, 'YYYY-MM-DD');
        // const nextMonth = currentDate.clone().add(1, 'month');
        // this.currentdDate = moment(nextMonth.format('YYYY-MM-DD'))

        // this.fIlterMonth.setMonth(this.fIlterMonth.getMonth() + 1)
        // this.fIlterMonth = moment(this.fIlterMonth).format('MMMM');
        
        // this.fIlterYear = this.fIlterMonth
        // this.month = moment(this.fIlterMonth).format('MMMM');
        // this.year = moment(this.fIlterYear).format('YYYY');
        // this.getCalendarView()      
        this.calendar = []
        const currentDate = moment(this.currentdDate, 'YYYY-MM-DD');
        const nextMonth = currentDate.clone().add(1, 'month');
        this.currentdDate = moment(nextMonth.format('YYYY-MM-DD'))
        const today = this.currentdDate
       
        // const startDay = today.clone().startOf('month').startOf('week')
        // // console.log('startDay', startDay)
        // const endDay = today.clone().endOf('month').endOf('week')
        // // console.log('enddate', endDay)
        // let date = startDay.clone().subtract(1, 'day')
        // while (date.isBefore(endDay, 'day'))
        //     this.calendar.push({
        //         days: Array(7).fill(0).map(() => {
        //             let day = date.add(1, 'day').clone()
        //             return { day: Number(day.format('DD')), date: day.format('YYYY-MM-DD') }
        //         })
        //     })
        this.filters.startDate = this.currentdDate.startOf('month').toDate()
        this.filters.endDate = this.currentdDate.endOf('month').toDate()
        this.getCalendarView()
        console.log(this.filters)
        this.filters = {
            startDate: moment(this.fIlterYear).clone().set('month', moment(this.currentdDate).month()).startOf('month').toDate(),
            endDate: moment(this.fIlterYear).clone().set('month', moment(this.currentdDate).month()).endOf('month').toDate(),
        };
        
        this.filters.startDate = moment(this.filters.startDate).format('YYYY-MM-DD');
        this.filters.endDate = moment(this.filters.endDate).format('YYYY-MM-DD');
   
        this.ds.getInspections(this.filters).subscribe((resp: any) => {
            if (resp.success === true) {
                this.inspections = resp.data
                if(resp.success) {
                
                    resp.data.forEach((e : any) => {
                        let day = e.date
                        e.day = new Date(day).getDate()
                    })
                    this.inspections = resp.data
                    this.dataStatus = 'done'
            }
            }
        })
        this.month = today.format('MMMM')
        this.year = today.format('YYYY')
        this.fIlterMonth = today.format('MMMM')
        this.fIlterYear = today.format('YYYY')
        
    }
    previousMonth() {
        this.calendar = []
        const currentDate = moment(this.currentdDate, 'YYYY-MM-DD');
        const nextMonth = currentDate.clone().subtract(1, 'month');
        this.currentdDate = moment(nextMonth.format('YYYY-MM-DD'))
        const today = this.currentdDate
       
        // const startDay = today.clone().startOf('month').startOf('week')
        // // console.log('startDay', startDay)
        // const endDay = today.clone().endOf('month').endOf('week')
        // // console.log('enddate', endDay)
        // let date = startDay.clone().subtract(1, 'day')
        // while (date.isBefore(endDay, 'day'))
        //     this.calendar.push({
        //         days: Array(7).fill(0).map(() => {
        //             let day = date.add(1, 'day').clone()
        //             return { day: Number(day.format('DD')), date: day.format('YYYY-MM-DD') }
        //         })
        //     })
        this.filters.startDate = this.currentdDate.startOf('month').toDate()
        this.filters.endDate = this.currentdDate.endOf('month').toDate()
        this.getCalendarView()
        console.log(this.filters)
        this.filters = {
            startDate: moment(this.fIlterYear).clone().set('month', moment(this.currentdDate).month()).startOf('month').toDate(),
            endDate: moment(this.fIlterYear).clone().set('month', moment(this.currentdDate).month()).endOf('month').toDate(),
        };
        
        this.filters.startDate = moment(this.filters.startDate).format('YYYY-MM-DD');
        this.filters.endDate = moment(this.filters.endDate).format('YYYY-MM-DD');
   
        this.ds.getInspections(this.filters).subscribe((resp: any) => {
            if (resp.success === true) {
                this.inspections = resp.data
                if(resp.success) {
                
                    resp.data.forEach((e : any) => {
                        let day = e.date
                        e.day = new Date(day).getDate()
                    })
                    this.inspections = resp.data
                    this.dataStatus = 'done'
            }
            }
        })
        this.month = today.format('MMMM')
        this.year = today.format('YYYY')
        this.fIlterMonth = today.format('MMMM')
        this.fIlterYear = today.format('YYYY')
        
    }
    fIlterMonthYear() {
        let month = moment(this.fIlterMonth).month()
        let year = moment(this.fIlterYear).year()
        this.currentdDate = moment().month(month).year(year)
        this.year = String(year)
        this.month = this.currentdDate.format('MMMM')
        this.filters.startDate = this.currentdDate.startOf('month').toDate()
        this.filters.endDate = this.currentdDate.endOf('month').toDate()
        this.calendar = []
        const startDay = this.currentdDate.clone().startOf('month').startOf('week')
        const endDay = this.currentdDate.clone().endOf('month').endOf('week')
        let date = startDay.clone().subtract(1, 'day')
        while (date.isBefore(endDay, 'day')) {
            this.calendar.push({
                days: Array(7).fill(0).map(() => {
                    let day = date.add(1, 'day').clone()
                    return { day: Number(day.format('DD')), date: day.format('YYYY-MM-DD') }
                })
            })
        }
        this.filters = {
            startDate: moment(this.fIlterYear).clone().set('month', moment(this.fIlterMonth).month()).startOf('month').toDate(),
            endDate: moment(this.fIlterYear).clone().set('month', moment(this.fIlterMonth).month()).endOf('month').toDate(),
        };
        
        this.filters.startDate = moment(this.filters.startDate).format('YYYY-MM-DD');
        this.filters.endDate = moment(this.filters.endDate).format('YYYY-MM-DD');
   
        this.ds.getInspections(this.filters).subscribe((resp: any) => {
            if (resp.success === true) {
                this.inspections = resp.data
                if(resp.success) {
                
                    resp.data.forEach((e : any) => {
                        let day = e.date
                        e.day = new Date(day).getDate()
                    })
                    this.inspections = resp.data
                    this.dataStatus = 'done'
            }
            }
        })
        
    }

    // teamSelect(): void {
    //     this.teamIds = this.g.teamIds.value
    //     if (this.teamIds.includes(0)) {
    //         this.g.teamIds.setValue([0])
    //         this.teamIds = []
    //         this.teamsList.forEach((element: any) => {
    //             this.teamIds.push(element.id)
    //         });
    //     }
    // }

    // startTimeChange() {
    //     //Endtime advances as start time advances
    //     const currentStartTime = moment(this.eventsForm.controls.startTime.value)
    //     const edTime = this.prevStartTime
    //     let diff = currentStartTime.diff(edTime)
    //     let duration = moment.duration(diff)
    //     console.log()
    //     let endtime = moment(this.eventsForm.controls.endTime.value)
    //         .add(duration.asMinutes(), 'minutes')
    //         .toString()
    //     this.eventsForm.controls.endTime.setValue(endtime)
    //     this.prevStartTime = currentStartTime
    // }
   
}
